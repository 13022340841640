import './App.css';
import Header from "./components/header/Header";

import {OrdersFactory} from "./components/OrdersFactory/OrdersFactory";
import {EventSourcing} from "./api/EventSourcing";
import CompletedOrders from "./components/CompletedOrders/CompletedOrders";
import {Route, Routes} from "react-router-dom";
import React, {useEffect} from "react";
import {useAppStore} from "./store/appStore";
import {OrderPopup} from "./components/OrderPopup/OrderPopup";
import {useCouriersStore} from "./store/couriers/couriersStore";

import {CashierLayout} from './pages/Cashier/CashierLayout'
import {Menu} from "./pages/Cashier/components/menu/Menu";
import {Client} from "./pages/Cashier/components/client/Client";

function App() {
    const {isOpenPopupOrder} = useAppStore()
    const {getCouriersAPI} = useCouriersStore()
    EventSourcing()

    useEffect(() => {
        getCouriersAPI()
    }, []);

  return (
    <div className="App">
        {isOpenPopupOrder ? <OrderPopup /> : ''}
      <Header/>
        <Routes>
            <Route path='/cashier' element={<CashierLayout />}>

                <Route index element={<Menu />}/>
                <Route path='client' element={<Client />}/>
                {/*<Route path='final' element={<Menu />}/>*/}
            </Route>
            <Route path="/manager/completedOrders" element={<CompletedOrders />} />
            <Route path="/*" element={<OrdersFactory />} />
        </Routes>

    </div>
  );
}

export default App;
