

import s from './CompletedOrders.module.scss'
import {useOrdersStore} from "../../store/orderStore";
import {useEffect} from "react";


export default function CompletedOrders() {
    const {completedOrders, getCompletedOrdersAPI} = useOrdersStore()

    useEffect(() => {
        getCompletedOrdersAPI()
    }, [])

    return (
        <div className={s.wrapper}>
            {completedOrders.map((order) => {
                return <div key={order.id} className={s.item}>
                    <span>Номер: {order.id},</span>
                    <span>Сумма: {order.items.total_sum}р,</span>
                    <span>От: {order.created_at.slice(11, 16)}</span>
                    <span>Завершен: {order.success_completion_at.slice(11, 16)}</span>

                </div>
            })}
        </div>
    )
}
