
import s from './Cart.module.scss'
import {useState} from "react";
import {
    decreaseProductCountAPI,
    removeProductAPI, removePromocodeFromOrderAPI
} from "../../../../store/cashier/cashierAPI";
import {useCashierStore} from "../../../../store/cashier/cashierStore";
import {isEmpty} from "lodash";
import {Spinner} from "../../CashierLayout";
export const Cart = () => {
    const {order} = useCashierStore()

    if (isEmpty(order)) {
        return ''
    }
    // console.log(111, order)
    return (
        <div className={s.wrapper}>
            <p>Заказ</p>
            <div className={s.cartListing}>
                {order.promocode.type === 1 && <PromocodeProduct item={order.promocode.effect}/>}
                {order.order.items.map((item, index) => {
                    return <CartItem key={index} item={item}/>
                })}
            </div>
            <div className={s.footerPlaceholder}>
                <div className={s.footer}>
                    <div>Сумма:</div>
                    {order.order.total_sum < order.order.sum && <div><del>{order.order.sum}</del>
                    </div>}
                    <div>{order.order.total_sum}р</div>
                </div>
            </div>
        </div>
    )
}


const CartItem = ({item}) => {
    const [isRemovingProduct, setIsRemovingProduct] = useState(false)
    const [isDecreasingProduct, setIsDecreasingProduct] = useState(false)
    return (
        <div className={s.cartItem}>
            <div className={isDecreasingProduct ? s.disactiveItemTitle : s.itemTitle}
                 onClick={async () => {
                     setIsDecreasingProduct(true)
                     await decreaseProductCountAPI(item.id)
                     setIsDecreasingProduct(false)
                 }}
            >
                {item.title}<br/>
                <span>{item.size}</span>
            </div>
            <div className={s.itemCount}>{item.quantity}</div>
            <div className={s.itemCount}
                 onClick={async () => {
                     setIsRemovingProduct(true)
                     await removeProductAPI(item.id)
                     setIsRemovingProduct(false)
                 }}
            >
                {isRemovingProduct ? <Spinner height={'16'} width={'16'}/> : item.sum}
                </div>
        </div>
    )
}


const PromocodeProduct = ({item}) => {
    const [isRemovingProduct, setIsRemovingProduct] = useState(false)
    const [isDecreasingProduct, setIsDecreasingProduct] = useState(false)
    return (
        <div className={s.cartItem}>
            <div className={isDecreasingProduct ? s.disactiveItemTitle : s.itemTitle}
                 onClick={async () => {
                     setIsDecreasingProduct(true)
                     await removePromocodeFromOrderAPI(item.id)
                     setIsDecreasingProduct(false)
                 }}
            >
                {item.title}<br/>
                <span>{item.size}</span>
            </div>
            <div className={s.itemCount}>{item.quantity}</div>
            <div className={s.itemCount}
                 onClick={async () => {
                     setIsRemovingProduct(true)
                     await removePromocodeFromOrderAPI(item.id)
                     setIsRemovingProduct(false)
                 }}
            >
                {isRemovingProduct ? <Spinner height={'16'} width={'16'}/> : item.price}
            </div>
        </div>
    )
}