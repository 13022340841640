import { create } from 'zustand'
import {devtools} from 'zustand/middleware'

export const useAppStore = create(devtools((set) => (
            {
                ordersFilter: '',
                setOrdersFilter: (data) => {
                    set({ordersFilter: data})
                },
                isOpenPopupOrder: false,
                setIsOpenPopupOrder: (bool) => {
                    set({isOpenPopupOrder: bool})
                },

            }
        ),
        {
            name: "app-storage"
        }
    )
)


