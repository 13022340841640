import {isEmpty, isNil} from "lodash";
import axios from "axios";

import {url} from "../../config";

import s from './OrderPopup.module.scss'
import {useOrdersStore} from "../../store/orderStore";
import {useAppStore} from "../../store/appStore";
import {useLocation} from "react-router-dom";

import {useEffect, useState} from "react";
import {useCouriersStore} from "../../store/couriers/couriersStore";
import {checkIsCancel} from "../../hooks/checkLocation";



export const OrderPopup = () => {
    const {popupOrder, orders} = useOrdersStore()
    const {setIsOpenPopupOrder} = useAppStore()
    const location = useLocation()
    useEffect(() => {}, [orders])
    // const isManager = checkIsManager(location)

    // console.log(popupOrder)
    // log
// # 0 в процессе
// # 1 начали готовить
// # 2 приготовлен
// # 3 доставляют
// # 4 доставлен
//
// # 5 reject
// # 6 validation

    function getBody () {
        if (checkIsCancel(location)) {
            return <CancelOrder setIsOpenPopupOrder={setIsOpenPopupOrder} />
        } else if (popupOrder.logStatus === 6) {
            return <ValidateOrder setIsOpenPopupOrder={setIsOpenPopupOrder}/>
        } else if (popupOrder.logStatus === 2 || popupOrder.logStatus === 3) {
            return <ToAssembleOrder setIsOpenPopupOrder={setIsOpenPopupOrder}/>
        } else if (popupOrder.logStatus === 0 || popupOrder.logStatus === 1) {
            return <CookingOrder setIsOpenPopupOrder={setIsOpenPopupOrder}/>
        }
    }

    return (
        <div className={s.wrapper}
             onClick={() => setIsOpenPopupOrder(false)}
        >
            <div className={s.content} onClick={(e) => e.stopPropagation()}>
                <div className={s.info}>
                    <h2 className={s.title}>Заказ {popupOrder.id}</h2>
                </div>
                {getBody()}

            </div>
        </div>
    )
}

const CookingOrder = ({setIsOpenPopupOrder}) => {
    const {popupOrder, setOrderStatusAPI} = useOrdersStore()

    const [orderInfo, setOrderInfo] = useState({})
    const getInfoAboutOrdersAPI = async () => {
        axios.get(`${url}/getinfoaboutorders?orderid=${popupOrder.id}`).then(function (response) {
            setOrderInfo(response.data)
        }).catch(function (error) {
            alert(error.response.data.detail)
            setOrderInfo({})
        })
    }

    useEffect(() => {
        getInfoAboutOrdersAPI()
    }, [])



    const checkStartCookingButton = () => {
        if (popupOrder.logStatus > 0) return false;
        return true
    }

    const checkCancelCookingButton = () => {
        if (popupOrder.logStatus !== 1) return false;
        return true
    }
    return (
        <>
            { !isEmpty(orderInfo) &&
                <>
                    {!isEmpty(orderInfo.user.number) &&
                        <div className={s.infoRow}>
                            <span>Номер</span>: +7{orderInfo.user.number}
                        </div>}

                    {!isEmpty(orderInfo.user.name) &&
                        <div className={s.infoRow}>
                            <span>Имя</span>: {orderInfo.user.name}
                        </div>}
                        <div className={s.infoRow}>
                            <span>Сумма</span>: {orderInfo.order.items.total_sum}p
                        </div>
                    {!isEmpty(orderInfo.order.items.address.street) &&
                        <div className={s.infoRow}>
                            <span>Адрес</span>: {orderInfo.order.items.address.street}
                            {!isEmpty(orderInfo.order.items.address.entrance) && `, ${orderInfo.order.items.address.entrance} п`}
                            {!isEmpty(orderInfo.order.items.address.floor) && `, ${orderInfo.order.items.address.floor} этаж`}
                            {!isEmpty(orderInfo.order.items.address.apartment) && `, ${orderInfo.order.items.address.apartment} кв`}
                        </div>
                    }


            {!isEmpty(orderInfo.order.items.comment) &&
                <div className={s.infoRow}>
                    <span>Комментарий</span>: {orderInfo.order.items.comment}
                </div>
            }
            <div className={s.infoRow}>
                <span>Оплата</span>: {orderInfo.order.items.paytype}
            </div>
                </>
            }

        <div className={s.buttons}>
            <button
                className={checkStartCookingButton() ? '' : s.disactive_button}
                onClick={() => checkStartCookingButton() ? setOrderStatusAPI(popupOrder.id, 1, setIsOpenPopupOrder) : ''}
            >Готовится
            </button>
            <button
                className={checkCancelCookingButton() ? '' : s.disactive_button}
                onClick={() => checkCancelCookingButton() ? setOrderStatusAPI(popupOrder.id, 2, setIsOpenPopupOrder) : ''}
            >Готов
            </button>
        </div>
            </>
    )
}

const ToAssembleOrder = ({setIsOpenPopupOrder}) => {
    const {popupOrder, setOrderStatusAPI} = useOrdersStore()
    const {couriers, getCouriersAPI} = useCouriersStore()

    const [orderInfo, setOrderInfo] = useState({})
    const getInfoAboutOrdersAPI = async () => {
        axios.get(`${url}/getinfoaboutorders?orderid=${popupOrder.id}`).then(function (response) {
            setOrderInfo(response.data)
        }).catch(function (error) {
            alert(error.response.data.detail)
            setOrderInfo({})
        })
    }
    const setCourierToOrderAPI = async (courierid) => {
        axios.post(`${url}/couriers/setcouriertoorder?orderid=${popupOrder.id}&courierid=${courierid}`).then(function (response) {
            setOrderInfo(response.data.order)
        }).catch(function (error) {
            alert('Произошла ошибка')
        }).finally(() => getInfoAboutOrdersAPI())
    }

    useEffect(() => {
        getInfoAboutOrdersAPI(popupOrder.id)
        getCouriersAPI()
    }, [popupOrder])

    console.log(orderInfo.courier)
    console.log(isNil(orderInfo.courier))
    if (popupOrder.type === 1) {
        return (
            <>
                { !isEmpty(orderInfo) &&
                    <>
                        {!isEmpty(orderInfo.user.number) &&
                            <div className={s.infoRow}>
                                <span>Номер</span>: +7{orderInfo.user.number}
                            </div>}

                        {!isEmpty(orderInfo.user.name) &&
                            <div className={s.infoRow}>
                                <span>Имя</span>: {orderInfo.user.name}
                            </div>}
                        <div className={s.infoRow}>
                            <span>Сумма</span>: {orderInfo.order.items.total_sum}p
                        </div>
                        {!isEmpty(orderInfo.order.items.address.street) &&
                            <div className={s.infoRow}>
                                <span>Адрес</span>: {orderInfo.order.items.address.street}
                                {!isEmpty(orderInfo.order.items.address.entrance) && `, ${orderInfo.order.items.address.entrance} п`}
                                {!isEmpty(orderInfo.order.items.address.floor) && `, ${orderInfo.order.items.address.floor} этаж`}
                                {!isEmpty(orderInfo.order.items.address.apartment) && `, ${orderInfo.order.items.address.apartment} кв`}
                            </div>
                        }


                        {!isEmpty(orderInfo.order.items.comment) &&
                            <div className={s.infoRow}>
                                <span>Комментарий</span>: {orderInfo.order.items.comment}
                            </div>
                        }
                        <div className={s.infoRow}>
                            <span>Оплата</span>: {orderInfo.order.items.paytype}
                        </div>
                    </>
                }
                <div className={s.couriersWrapper}>
                    {couriers.map((courier) => {
                        return (
                            <div className={orderInfo.courier === courier.id ? s.active_courier : s.courier}
                                 key={courier.id}
                                 onClick={() => setCourierToOrderAPI(courier.id)}
                            >{`${courier.name} ${courier.surname}`}</div>
                        )
                    })}
                </div>

                <div className={s.buttons}>
                    <button
                        disabled={isNil(orderInfo.courier)}
                        onClick={() => setOrderStatusAPI(popupOrder.id, 3, setIsOpenPopupOrder)}
                    >Доставляется
                    </button>
                </div>
            </>
        )
    }
    return (
        <>
            <div className={s.buttons}>
                <button
                    onClick={() => setOrderStatusAPI(popupOrder.id, 4, setIsOpenPopupOrder)}
                >Отдан
                </button>
            </div>
        </>
    )
}

const CancelOrder = ({setIsOpenPopupOrder}) => {
    const {popupOrder, cancelOrderAPI} = useOrdersStore()


    return (
        <div className={s.buttons}>
            <button
                onClick={() => cancelOrderAPI(popupOrder.id, setIsOpenPopupOrder)}
            >Отменить заказ
            </button>
        </div>
    )
}




const ValidateOrder = ({setIsOpenPopupOrder}) => {
    const {popupOrder, validateOrderAPI, order, getInfoAboutOrdersAPI} = useOrdersStore()

    useEffect(() => {
        getInfoAboutOrdersAPI(popupOrder.id)
    }, [popupOrder])

    return (
        <>
            {Object.keys(order).length > 0 ? (
                    <>
                        <div className={s.infoRow}>
                            <span>Номер</span>: +7{order.user.number}
                        </div>

                        <div className={s.infoRow}>
                            <span>Имя</span>: {order.user.name}
                        </div>

                        <div className={s.infoRow}>
                            <span>Сумма</span>: {order.order.total_sum}p
                        </div>

                        <div className={s.infoRow}>
                            <span>Улица</span>: {order.address}
                        </div>
                        <div className={s.infoRow}>
                            <span>Дом</span>: {order.order.house}
                        </div>
                        <div className={s.infoRow}>
                            <span>Подъезд</span>: {order.order.entrance}
                        </div>
                        <div className={s.infoRow}>
                            <span>Этаж</span>: {order.order.floor}
                        </div>
                        <div className={s.infoRow}>
                            <span>Квартира</span>: {order.order.apartment}
                        </div>
                        <div className={s.infoRow}>
                            <span>Комментарий</span>: {order.order.comment}
                        </div>

                        <div className={s.infoRow}>
                            <span>Оплата</span>: {order.type === 1 ? 'Карта' : 'Наличные'}
                        </div>
                    </>
                )
                : ''
            }


            <div className={s.buttons}>
                <button
                    onClick={() => true ? validateOrderAPI(popupOrder.id, false, setIsOpenPopupOrder) : ''}
                >Отменить
                </button>
                <button
                    onClick={() => true ? validateOrderAPI(popupOrder.id, true, setIsOpenPopupOrder) : ''}
                >Принять
                </button>
            </div>
        </>
    )

}