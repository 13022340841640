import {url} from '../config'
import axios from "axios";

axios.defaults.withCredentials = true

export function setStatus (orderID, statusID){
    return axios.post(`${url}/setlogstatus?orderid=${orderID}&statusid=${statusID}`)
}

export function validateOrder (orderID, bool){
    return axios.post(`${url}/validateorder?orderid=${orderID}&bool=${bool}`)
}

export function getInfoAboutOrders (orderID){
    return axios.get(`${url}/getinfoaboutorders?orderid=${orderID}`)
}

export function getCompletedOrders (){
    return axios.get(`${url}/getcompletedorders`)
}

export function cancelOrder (orderid){
    return axios.post(`${url}/cancelorder?orderid=${orderid}`)
}