import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {cancelOrder, getCompletedOrders, getInfoAboutOrders, setStatus, validateOrder} from "./orderAPI";

export const useOrdersStore = create(devtools((set) => (
            {
                orders: [],
                setOrders: (data) => {
                    if (data.length > 0) {
                        data.sort((a, b) => {
                            if (a.id < b.id) {
                                return -1
                            }
                        })
                    }
                    set({orders: data})
                },

                reciveOrders: false,
                setReciveOrders: (bool) => {
                    set({reciveOrders: bool})
                },

                popupOrder: {},
                setPopupOrder: (data) => {
                    set({popupOrder: data})
                },

                completedOrders: [],
                getCompletedOrdersAPI: (data) => {
                    getCompletedOrders().then(function (response) {
                        set({completedOrders: response.data.orders})
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                        set({completedOrders: []})
                    })
                },

                order: {},
                setOrder: (order) => {
                    set({order: order})
                },
                loadingOrder: false,
                getInfoAboutOrdersAPI: async (orderId) => {
                    set({loadingOrder: true})
                    await getInfoAboutOrders(orderId).then(function (response) {
                        set({order: response.data})
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                        set({order: {}})
                    })
                    set({loadingOrder: false})
                },

                setOrderStatusAPI: (orderId, status, setIsOpenPopupOrder) => {
                    setStatus(orderId, status).then(function (response) {
                        setIsOpenPopupOrder(false)
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                    })
                },

                validateOrderAPI: (orderId, bool, setIsOpenPopupOrder) => {
                    validateOrder(orderId, bool).then(function (response) {
                        setIsOpenPopupOrder(false)
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                    })
                },


                cancelOrderAPI: (orderId, setIsOpenPopupOrder) => {
                    cancelOrder(orderId).then(function (response) {
                        setIsOpenPopupOrder(false)
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                    })
                },
            }
        ),
        {
            name: "orders-storage"
        }
    )
)

