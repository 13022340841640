import { create } from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'

export const useCashierStore = create(devtools((set) => (
            {
                products: [],
                setProducts: (data) => {
                    data.map((item) => item.items.sort(function(a, b) {
                        if (a.title > b.title) {
                            return 1;
                        }
                        if (a.title < b.title) {
                            return -1;
                        }
                        // если имена равны
                        return 0;
                    }))

                    set({products: data})
                },

                order: {},
                setOrder: (data) => {
                    set({order: data})
                },

                paytypes: [],
                setPaytypes: (data) => {
                    set({paytypes: data})
                },

                selectedPaytype: Number,
                setSelectedPaytype: (data) => {
                    set({selectedPaytype: data})
                },

                number: '',
                setNumber: (data) => {
                    if (data.length < 11) {
                        set({number: data})
                    }
                },

                numbersList: [],
                setNumbersList: (data) => {
                    set({numbersList: data})
                },

                promocode: '',
                setPromocode: (data) => {
                    set({promocode: data})
                },

            }
        ),
        {
            name: "cashier-storage"
        }
    )
)

