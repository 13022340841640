import {url} from './../config'

import {useEffect, useRef} from "react";
import {useOrdersStore} from "../store/orderStore";


export const EventSourcing= () => {
    const {setOrders, setReciveOrders} = useOrdersStore()
    const eventSource = useRef()
    const onUpdateOrders = (data) => {
        const ord = JSON.parse(data)
        setOrders(ord.orders)
        setReciveOrders(true)
    }

    useEffect(() => {
        tryToConnect()
    }, []);


    const tryToConnect = () => {
        eventSource.current = new EventSource(
            `${url}/connecttoorderstream`
        )

        eventSource.current.onopen = onOpen;
        eventSource.current.onmessage = onMessage;
        eventSource.current.onclose = onClose;
        eventSource.current.onerror = onError;
    }

    function onOpen(e) {
        console.log('Успешное соединение');
    };

    function onClose(e) {
        console.log('Соединение закрыто. Попытка повторного подключения');
        setReciveOrders(false)
        setTimeout(() => tryToConnect(), 10000); // повторное подключение через 1 секунду
    }

    const onMessage = (event) => {
        onUpdateOrders(event.data)
    }

    function onError(e) {
        console.log('Ошибка соединения');
        // tryToConnect()
        setReciveOrders(false)
    }
}