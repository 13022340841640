import {Outlet} from "react-router-dom";

import s from './CashierLayout.module.scss'

import {CashierHeader} from "./components/header/CashierHeader";
import {Cart} from "./components/cart/Cart";
import {useEffect} from "react";
export const CashierLayout = () => {
    useEffect(() => {
        document.title = 'Кассир';
    }, []);
    return (
        <>
        <title>Касса</title>
        <div className={s.wrapper}>
            <CashierHeader />

            <main className={s.content}>
                <Outlet/>
                <Cart />

            </main>
        </div>
            </>
    )
}

export const Spinner = ({width, height}) => {
    return (<svg className={s.spinner} viewBox="0 0 50 50" style={{width: width + 'px', height: height + 'px'}}>
        <circle className={s.path} cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>)
}