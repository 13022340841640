import {url} from '../../config'
import axios from "axios";

axios.defaults.withCredentials = true

export function getCouriers (){
    return axios.get(`${url}/couriers/`)
}




