
import s from './OrdersFactory.module.scss'
import {useOrdersStore} from "../../store/orderStore";
import ComputeTime from "../../hooks/computeTime";
import {useAppStore} from "../../store/appStore";
import {useEffect, useState} from "react";
import OrderProducts from "./OrderProducts/OrderProducts";
import {checkIsManager} from "../../hooks/checkManager";
import {useLocation} from "react-router-dom";
import {isNil} from "lodash";

export const OrdersFactory = (type) => {
    const {orders, reciveOrders} = useOrdersStore()
    const {ordersFilter} = useAppStore()

    const [ordersArray, setOrdersArray] = useState([])
    const location = useLocation()

    const isManager = checkIsManager(location)
    useEffect(() => {
        if (ordersFilter === '' || ordersFilter === 'cancelOrder') {
            setOrdersArray(isManager? orders : orders.filter((order) => order.logStatus !== 3))
        } else if (ordersFilter === 'ready') {
            setOrdersArray(orders.filter((order) => order.logStatus === 2))
        } else if (ordersFilter === 'products') {
            setOrdersArray(orders.filter((order) => order.logStatus >= 0 && order.logStatus < 2))
        } else if (ordersFilter === 'waitDelivering') {
            setOrdersArray(orders.filter((order) => order.logStatus === 2 && order.type === 1))
        } else if (ordersFilter === 'waitConfirm') {
            setOrdersArray(orders.filter((order) => order.logStatus === 6))
        }
    }, [orders, ordersFilter])

    if (reciveOrders) {
        if (ordersFilter === 'products') {
            return (
                <div className={s.wrapper}>
                    {ordersArray.map((order) => <OrderProducts key={order.id} order={order}/>)}
                </div>)
        } else {
            return (
                <div className={s.wrapper}>
                    {ordersArray.map((order) => <Order key={order.id} order={order}/>)}
                </div>
            )
        }
    } else {
        return ''
    }
}

function Order({order}) {
    const location = useLocation()

    const isManager = checkIsManager(location)
    const {setPopupOrder} = useOrdersStore()
    const {setIsOpenPopupOrder} = useAppStore()

    // console.log(order)
    const statusTranslater = (log) => {
        if (log === 0) {
            return 'Принят'
        } else if (log === 1) {
            return 'Готовится'
        } else if (log === 2) {
            return 'Готов'
        } else if (log === 3) {
            return 'Доставляется'
        } else if (log === 4) {
            return 'Доставлен'
        } else if (log === 6) {
            return 'Ожидает подтверждение менеджера'
        }
    }

    const typeTranslater = (num) => {
        if (num === 1) {
            return <div className={s.info} style={{color: '#FF0000'}}> <span className={s.light_subtitle}>Тип: </span>Доставка</div>
        } else if (num === 0) {
            return <div className={s.info} style={{color: '#7000FF'}}> <span className={s.light_subtitle}>Тип: </span>Внутри</div>
        } else if (num === 2) {
            return <div className={s.info} style={{color: '#7000FF'}}> <span className={s.light_subtitle}>Тип: </span>Самовывоз</div>
        }
    }

    const bgColorChecker = (log) => {
        if (log === 0) {
            return '#F3F2F7'
        } else if (log === 1) {
            return '#FCFF73'
        } else if (log === 2) {
            return '#9CE4FB'
        } else if (log === 3) {
            return '#87FF73'
        } else if (log === 4) {
            return 'rgba(255,0,0,0.26)'
        } else if (log === 6) {
            return 'rgba(94,0,255,0.27)'
        }
    }

    const borderColorChecker = (log) => {
        if (log === 6) {
            return '5px solid rgba(94,0,255)'
        }
    }

    return (
        <div className={s.item} style={{border: borderColorChecker(order.logStatus)}}
             onClick={() => {
                 if (isManager) {
                     setPopupOrder({...order})
                     setIsOpenPopupOrder(true)
                 }
             }}
        >

            <p className={s.title} style={{backgroundColor: bgColorChecker(order.logStatus)}}>Заказ {order.id}</p>
            <div className={s.info}><span
                className={s.light_subtitle}>Статус: </span>{statusTranslater(order.logStatus)}</div>
            <div className={s.info}><span className={s.light_subtitle}>Позиций: </span>{order.product_count}</div>
            <div className={s.info}><span className={s.light_subtitle}>Сумма: </span>{order.total_sum}р</div>
            {!isNil(order.user_order_count) &&
            <div className={s.info}><span className={s.light_subtitle}>НЗП: </span>{order.user_order_count}</div>
            }
            {typeTranslater(order.type)}
            <div className={s.info}><span className={s.light_subtitle}>Оформлен: </span>{order.created_at}</div>
            <div className={s.info} style={{marginTop: '20px'}}><span className={s.light_subtitle}>Прошло времени: </span>{ComputeTime(order.created_at_datetime)}</div>
            {!isNil(order.user_order_count) && order.user_order_count === 1 &&
                <div className={s.user_order_count}>Первый заказ!</div>
            }
        </div>
    )
}


