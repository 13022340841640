import {Link} from "react-router-dom";

import s from './Menu.module.scss'
import {useEffect, useState} from "react";
import {addProductAPI, getOrderAPI, getProductsAPI} from "../../../../store/cashier/cashierAPI";
import {useCashierStore} from "../../../../store/cashier/cashierStore";
import {Spinner} from "../../CashierLayout";
export const Menu = () => {
    const {products} = useCashierStore()

    useEffect(() => {
        getProductsAPI()
        getOrderAPI()
    }, []);

    return (
        <div className={s.wrapper}>
            <div className={s.categoryWrapper}>
                {products.map(item => {
                    return <Category category={item} />
                })}
            </div>
        </div>
    )
}


const Category = ({category}) => {
    return (
        <div className={s.category}>
            <h6>{category.type}</h6>
            <div className={s.categoryItems}>
            {category.items.map((item, index) => {
                return <div className={s.categoryItem} key={index}>
                    <div className={s.title}>{item.title}</div>
                    <div className={s.sizesItems}>
                        {item.sizes.map((element, index) => {
                            return <SizeItem key={index} element={element} price={item.prices[index]} id={item.id[index]}/>
                        })}
                    </div>
                </div>
            })}
            </div>
        </div>
    )
}

const SizeItem = ({element, price, id}) => {
    const [isLoading, setIsLoading] = useState(false)
    console.log(price)
    return <div className={s.sizeItemWrapper}>
        <div className={s.sizeItemPrice}>{price}р</div>
        <div
        className={isLoading ? s.sizeItemLoading : s.sizesItem}
        onClick={async () => {
            if (!isLoading) {
                setIsLoading(true)
                await addProductAPI(id)
                setIsLoading(false)
            }
        }}
        >{isLoading ? <Spinner height={'16'} width={'16'}/> : element}</div>
    </div>
}

