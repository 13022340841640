import { create } from 'zustand'
import {devtools} from 'zustand/middleware'
import {getCouriers} from "./couriersAPI";


export const useCouriersStore = create(devtools((set) => (
            {
                couriers: [],
                getCouriersAPI: () => {
                    getCouriers().then(function (response) {
                        // console.log(response)
                        set({couriers: response.data.couriers})
                    }).catch(function (error) {
                        alert(error.response.data.detail)
                        set({couriers: []})
                    })
                },


            }
        ),
        {
            name: "courier-storage"
        }
    )
)

