import {url} from '../../config'
import axios from "axios";
import {useCashierStore} from "./cashierStore";

axios.defaults.withCredentials = true

const {setProducts, setOrder, setPaytypes, setSelectedPaytype, setNumber,
    setNumbersList, setPromocode
} = useCashierStore.getState()
export async function getProductsAPI (){
    await axios.get(`${url}/cashier/`).then(function (response) {
        if (response.data.status) {
            console.log(response.data.data.products)
            setProducts(response.data.data.products)

            // const sorted = response.data.data.products.sort((a, b) => a.localeCompare(b));
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export async function getOrderAPI (){
    await axios.get(`${url}/cashier/getOrder`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}


export async function getPaytypesAPI (){
    await axios.get(`${url}/cashier/paytypes`).then(function (response) {
        if (response.data.status) {
            setPaytypes(response.data.data.paytypes)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export async function choosePaytypeAPI (id){
    await axios.post(`${url}/cashier/choosePaymentType?pay_type=${id}`).then(function (response) {
        if (response.data.status) {
            // setOrder(response.data.data)
            setSelectedPaytype(response.data.data.paytypeID)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function () {
        alert('Произошла неизвестная ошибка')
    })
}

export async function addProductAPI (id){
    await axios.post(`${url}/cashier/addToOrder?menu_id=${id}`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function () {
        alert('Произошла неизвестная ошибка')
    })
}

export async function decreaseProductCountAPI (id){
    await axios.put(`${url}/cashier/decreaseQuantity?menu_id=${id}`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function () {
        alert('Произошла неизвестная ошибка')
    })
}

export async function removeProductAPI (id){
    await axios.delete(`${url}/cashier/removeFromCart?menu_id=${id}`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function () {
        alert('Произошла неизвестная ошибка')
    })
}


export async function getNumbersAPI (str){
    await axios.get(`${url}/cashier/getNumbers?number=${str}`).then(async function (response) {
        if (response.data.status) {
            setNumbersList(response.data.data.numbers)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}


export async function addNumberToOrderAPI (number){
    await axios.post(`${url}/cashier/addNumberToOrder?number=8${number}`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export async function removeNumberToOrderAPI (){
    await axios.delete(`${url}/cashier/removeNumberFromOrder`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export async function addPromocodeToOrderAPI (promocode){
    await axios.post(`${url}/cashier/addPromocode?promocode_short_name=${promocode}`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}

export async function removePromocodeFromOrderAPI (promocode){
    await axios.post(`${url}/cashier/removePromocode`).then(function (response) {
        if (response.data.status) {
            setOrder(response.data.data)
        } else {
            alert(response.data.errorMessage)
        }

    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}


export async function finishOrderAPI (){
    await axios.post(`${url}/cashier/finishOrder`).then(function (response) {
        if (response.data.status) {
            setOrder({})
            setNumbersList([])
            setSelectedPaytype(Number)
            setNumber('')
            setPromocode('')
        } else {
            alert(response.data.errorMessage)
        }
    }).catch(function (error) {
        alert('Произошла неизвестная ошибка')
    })
}
