import s from "./OrdersProducts.module.scss";
import ComputeTime from "../../../hooks/computeTime";
import {useOrdersStore} from "../../../store/orderStore";
import {useAppStore} from "../../../store/appStore";

export default function OrderProducts({order}) {
    const {setPopupOrder} = useOrdersStore()
    const {setIsOpenPopupOrder} = useAppStore()

    if (order.mainStatus === 1) return '';

    const statusTranslater = (num) => {
        if (num === 0) {
            return 'Принят'
        } else if (num === 1) {
            return 'Готовится'
        } else if (num === 2) {
            return 'Готов'
        } else if (num === 3) {
            return 'Доставляется'
        } else if (num === 4) {
            return 'Доставлен'
        } else if (num === 5) {
            return 'Ожидает подтверждение менеджера'
        }
    }


    const typeTranslater = (num) => {
        if (num === 1) {
            return <div className={s.info} style={{color: '#FF0000'}}> <span className={s.light_subtitle}>Тип: </span>Доставка</div>
        } else if (num === 0) {
            return <div className={s.info} style={{color: '#7000FF'}}> <span className={s.light_subtitle}>Тип: </span>Внутри</div>
        } else if (num === 2) {
            return <div className={s.info} style={{color: '#7000FF'}}> <span className={s.light_subtitle}>Тип: </span>Самовывоз</div>
        }
    }


    // console.log(111, order)
    const borderColorChecker = (main) => {
        if (order.logStatus === 0) {
            return '5px dashed red'
        }
    }


    return (
        <div className={s.item}
             style={{backgroundColor: '#F3F2F7', border: borderColorChecker(order.mainStatus)}}
            onClick={() => {
                setPopupOrder({...order})
                setIsOpenPopupOrder(true)
            }}
        >
            <p className={s.title}>Заказ {order.id}</p>
            <div className={s.info}><span
                className={s.light_subtitle}>Прошло времени: </span>{ComputeTime(order.created_at_datetime)}</div>
            <div className={s.info}><span
                className={s.light_subtitle}>Статус: </span>{statusTranslater(order.logStatus)}</div>
            <div className={s.info}><span className={s.light_subtitle}>Позиций: </span>
                {order.promocode.type === 1 ? order.product_count + 1 : order.product_count}
            </div>
            {typeTranslater(order.type)}

            <div className={s.positionsTitle}>Позиции</div>
            {order.promocode.type === 1 && <PromocodeProduct item={order.promocode.item}/>}
            {order.items.map((item, index) => {
                return <div className={s.position} key={index} onClick={(e) => e.stopPropagation()}>
                    <span className={s.checkboxTitle}>
                        <input type={'checkbox'} className={s.checkbox}/>
                        {item.title}  {item.size}
                    </span>
                    <span>{item.quantity}</span>
                </div>
            })}
        </div>
    )
}


const PromocodeProduct = ({item}) => {
    return (
        <div className={s.position} key={item.id} onClick={(e) => e.stopPropagation()}>
                    <span className={s.checkboxTitle}>
                        <input type={'checkbox'} className={s.checkbox}/>
                        {item.title} {item.size}
                    </span>
            <span>1</span>
        </div>
    )
}
