import {useEffect, useState} from "react";


export default function useInterval(interval, enabled) {
    const [now, setNow] = useState()

    useEffect(() => {
        if (!enabled) {
            setNow(undefined)
            return;
        }
        const int = setInterval(() => {
            setNow(new Date(Date.now()))
        }, interval)

        return () => {
            clearInterval(int)
        };
    }, [interval, enabled]);

    return now
}