import {Link} from "react-router-dom";

import s from './Client.module.scss'
import {useEffect, useState} from "react";
import {
    addNumberToOrderAPI,
    addPromocodeToOrderAPI,
    choosePaytypeAPI, finishOrderAPI, getNumbersAPI,
    getOrderAPI,
    getPaytypesAPI,
    removeNumberToOrderAPI
} from "../../../../store/cashier/cashierAPI";
import {useCashierStore} from "../../../../store/cashier/cashierStore";
import {Spinner} from "../../CashierLayout";
import {isEmpty} from "lodash";
export const Client = () => {
    const {order, selectedPaytype} = useCashierStore()
    const [isLoadingFinishAPI, setIsLoadingFinishAPI] = useState(false)
    useEffect(() => {
        getOrderAPI()
        getPaytypesAPI()
    }, []);

    const isFinishOrderButtonActive = () => {
        if (selectedPaytype !== Number) {
            return false
        }
        return true
    }

    return (
        <div className={s.wrapper}>
            <User />
            <button
                className={s.finishOrder}
                onClick={async () => {
                    setIsLoadingFinishAPI(true)
                    await finishOrderAPI()
                    setIsLoadingFinishAPI(false)
                }}
                disabled={isFinishOrderButtonActive() || isLoadingFinishAPI}
            >Оформить заказ</button>
        </div>
    )
}

const User = () => {
    const {number, setNumber, paytypes,
        selectedPaytype, numbersList, setNumbersList, order,
        promocode, setPromocode} = useCashierStore()
    const [isLoadingPaytype, setIsLoadingPaytype] =useState(false)
    const [isLoadingAddingNumber, setIsLoadingAddingNumber] =useState(false)
    const [isLoadingRemovingNumber, setIsLoadingRemovingNumber] =useState(false)
    const [isLoadingAddingPromocode, setIsLoadingAddingPromocode] =useState(false)

    const formatNumber = (str) => {
        const s = str.split('')
        s.splice(3, 0, ' ')
        s.splice(7, 0, ' ')
        s.splice(10, 0, '-')
        return s.join('')
    }

    const onChangeNumberInput = async (value) => {
        setNumber(value)
        if (!isEmpty(value) ) {
            if (!isEmpty(order.order.user)) {
                setIsLoadingRemovingNumber(true)
                await removeNumberToOrderAPI ()
                setIsLoadingRemovingNumber(false)
            }
            await getNumbersAPI(value)
        } else {
            setNumbersList([])
        }
    }

    const onClickToSuggestedNumber = async (value) => {
        setIsLoadingAddingNumber(true)
        await addNumberToOrderAPI(value)
        setIsLoadingAddingNumber(false)
        setNumber(value)
        setNumbersList([])
    }

    if (isEmpty(numbersList) && number.length === 10) {
        setNumbersList([{number: number.toString()}])
    }

    const isEmptyUser = () => {
        if (!isEmpty(order)) {
            return !isEmpty(order.order.user)
        }
        return false
    }

    const getPromocodeMessageStyle = () => {
        if (isEmpty(order)) {
            return ''
        }
        if (!order.promocode.applied) {
            return s.promocodeRed
        } else if (order.promocode.applied && order.promocode.linked) {
            return s.promocodeGreen
        }
        return ''
    }

    const getPromocodeInputStyle = () => {
        if (isEmpty(order)) {
            return
        }
        if (order.promocode.applied) {
            return {backgroundColor: 'greenyellow'}
        }
        return null
    }

    useEffect(() => {
        if (!isEmpty(order)) {
            if (order.promocode.applied || order.promocode.linked) {
                setPromocode(order.promocode.promocode)
            }
            if (!isEmpty(order.order.user)) {
                setNumber(order.order.user.number)
            }
        }
    }, [order]);

    console.log(order.promocode)
    return (
        <div className={s.userWrapper}>
            <p>Клиент</p>
            <div className={s.userContent}>
                <div className={s.input}>
                    <div className={s.inputTitle} >Номер: </div>
                    <input type="number"
                           value={number}
                           placeholder={'Номер без 8 или +7'}
                           disabled={isLoadingAddingNumber || isLoadingRemovingNumber}
                           style={isEmptyUser() ? {backgroundColor: 'greenyellow'} : {}}
                           onChange={(e) => {
                               onChangeNumberInput(e.target.value.toString())
                           }
                    }/>
                    <div className={s.suggestedNumbers}>
                        {!isEmptyUser() && numbersList.map((item) => {
                            return <div key={item.number}
                                className={s.suggestedNumber}
                                onClick={() => {
                                    onClickToSuggestedNumber(item.number)
                                }}
                            >{formatNumber(item.number)}</div>
                        })}
                    </div>

                </div>

                {!isEmpty(paytypes) &&
                <div className={s.input}>
                    <div className={s.inputTitle}>
                        Тип оплаты:
                    </div>
                    <div className={s.slider}>
                        {isLoadingPaytype ? 'Загрузка...': paytypes.map((item, index) => {
                            return <div key={index}
                                        className={selectedPaytype === item.id && s.activePaytype}
                                        onClick={async () => {
                                            setIsLoadingPaytype(true)
                                            await choosePaytypeAPI(item.id)
                                            setIsLoadingPaytype(false)
                                        }
                            }
                            >{item.name}</div>
                        })}
                    </div>
                </div>
                }


                <div className={s.promocode}>
                    <div className={s.inputPromocode}>
                        <p>Промокод</p>
                        <input
                            type="text"
                            value={promocode}
                            style={getPromocodeInputStyle()}
                            disabled={isLoadingAddingPromocode}
                            onChange={(e) => setPromocode(e.target.value)}
                        />

                        <button
                            disabled={isLoadingAddingPromocode}
                            onClick={async () => {
                                setIsLoadingAddingPromocode(true)
                                await addPromocodeToOrderAPI(promocode)
                                setIsLoadingAddingPromocode(false)
                            }}
                        >Применить
                        </button>
                    </div>
                    <div className={getPromocodeMessageStyle()}>
                        {!isEmpty(order) && order.promocode.message}
                    </div>

                </div>
            </div>
        </div>
    )
}

