import {Link, useLocation} from "react-router-dom";

import s from './Header.module.scss'
import {useAppStore} from "../../store/appStore";
import {useOrdersStore} from "../../store/orderStore";
import {useEffect, useState} from "react";
import {setStatus} from "../../store/orderAPI";
import {checkIsManager, checkPath} from "../../hooks/checkManager";

export default function Header() {
    const {ordersFilter, setOrdersFilter} = useAppStore()
    const {orders, reciveOrders} = useOrdersStore()
    const [haveConfirmNeeded, setHaveConfirmNeeded] = useState(false)
    const checkStyle = (str) => ordersFilter === str ? s.link : s.link_disactive





    useEffect(() => {
        const findUnConfirmedOrders = orders.find((i) => i.mainStatus === 1)
        if (findUnConfirmedOrders) {
            setHaveConfirmNeeded(true)
        } else {
            setHaveConfirmNeeded(false)
        }
    }, [orders, reciveOrders])

    const location = useLocation()

    if (location.pathname === '/cashier' || location.pathname === '/cashier/client' ) {
        return ''
    }

    const isManager = checkIsManager(location)

    return (
        <header className={s.wrapper}>
            <Link to={`${isManager ? '/manager' : ''}`} className={checkStyle('')} onClick={() => setOrdersFilter('')}>Все заказы</Link>
            <Link to={`${isManager ? '/manager' : ''}/products`} className={checkStyle('products')} onClick={() => setOrdersFilter('products')}>Кухня</Link>

            {
                isManager ?
                    <>
                    <Link to={`${isManager ? '/manager' : ''}/ready`} className={checkStyle('ready')} onClick={() => setOrdersFilter('ready')}>Сборка</Link>
                    <Link to={`${isManager ? '/manager' : ''}/waitDelivering`} className={checkStyle('waitDelivering')} onClick={() => setOrdersFilter('waitDelivering')}>На доставку</Link>
                    <Link to={`${isManager ? '/manager' : ''}/waitConfirm`} className={checkStyle('waitConfirm')} onClick={() => setOrdersFilter('waitConfirm')}
                          style={{border: haveConfirmNeeded ? '2px dashed rgb(94, 0, 255)' : '', backgroundColor: haveConfirmNeeded ? 'rgba(94,0,255,0.13)' : ''}}
                    >Ожидают подверждения</Link>
                    <Link to={`${isManager ? '/manager' : ''}/completedOrders`} className={checkStyle('completedOrders')} onClick={() => setOrdersFilter('completedOrders')}>Завершенные заказы</Link>
                    <Link to={`${isManager ? '/manager' : ''}/cancelOrder`} className={checkStyle('cancelOrder')} onClick={() => setOrdersFilter('cancelOrder')}>Отменить заказ</Link>
                    </>
                    : ''
            }


        </header>
    )
}