import useInterval from "./useInterval";


export default function ComputeTime(startTime) {
    const now = useInterval(1000, startTime)
    const timeStart = new Date(startTime)
    const timer = (now - timeStart) / 1000
    const hours = Math.floor(timer / 3600)
    const minutes = Math.floor((timer  / 60) - (hours * 60))
    var seconds = timer % 60;
    var formatted = `${hours > 0 ? hours + ':' : ''}${minutes <= 0 ? '00' : `${minutes < 10 ? '0'+minutes : minutes}`}:${seconds === 0 ? '00' : `${seconds < 10 ? '0'+seconds : seconds}`}`;

    return formatted.split('.')[0]
}