import {Link, useLocation} from "react-router-dom";

import s from './CashierHeader.module.scss'
export const CashierHeader = () => {
    const location = useLocation()

    const list = [
        {
            title: 'Меню',
            link: '/cashier',
        },
        {
            title: 'Клиент',
            link: '/cashier/client',
        },
        // {
        //     title: 'Финал',
        //     link: '/cashier/final',
        // },
    ]
    return (
        <div className={s.wrapper}>
            {
                list.map((item, index) => {
                    return <Link to={item.link} key={index} className={location.pathname === item.link ? s.active : ''}>{item.title}</Link>
                })
            }
        </div>
    )
}